import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import css from '@styled-system/css';
import { XCircle } from 'react-feather';

import { fadeIn } from 'utils/animations';
import Card from 'legacy/components/card';
import Box from 'legacy/components/box';

const StyledModal = styled(Box)(({ isOpen }) =>
  css({
    display: isOpen ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.25)',
    zIndex: 500,
  })
);

const Overlay = styled(Box)(
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  })
);

const Content = styled(Card)`
  position: relative;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding: 0;
  overflow: auto;
  animation: ${fadeIn} 0.5s ease;
  z-index: 1000;
  ${space}
  ${color}
`;

const CloseIcon = styled.button(
  css({
    position: 'absolute',
    top: 3,
    right: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: 0,
    p: 0,
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    appearance: 'none',
    '& > svg': {
      color: 'gray50',
      '&:hover': {
        color: 'gray30',
      },
    },
  })
);

const Modal = ({ isOpen, onClose, contentLabel, children, ...props }) => {
  useEffect(() => {
    const handleEscPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscPress);

    return () => window.removeEventListener('keydown', handleEscPress);
  }, [onClose]);

  return (
    <StyledModal isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <Content
        isOpen={isOpen}
        role="dialog"
        aria-label={contentLabel}
        aria-modal="true"
        {...props}
      >
        <CloseIcon aria-label="close" onClick={onClose}>
          <XCircle />
        </CloseIcon>
        <Box width={1}>{children}</Box>
      </Content>
    </StyledModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
