import { ContentfulSysInterface } from '../../../types/common/contentfulSys.types';

import {
  ImageFragment,
  imageFragment,
} from 'legacy/graphql/fragments/components/imageFragment';

export const inputOptionFragment = (withImage = false) => `
  ... on InputOption {
    sys {
      id
    }
    label
    value
    ${withImage ? `image {${imageFragment}}` : ''}
  }
`;

export type InputOptionFragment = ContentfulSysInterface & {
  image?: ImageFragment | null;
  label: string;
  value: string;
};
