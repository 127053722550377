import PropTypes from 'prop-types';

import { contentfulImageType, contentfulSysType } from 'types';

export const testimonialCardProps = {
  backgroundColor: PropTypes.oneOf(['white', 'gray100']).isRequired,
  image: contentfulImageType.isRequired,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
};

export const testimonialCardType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...testimonialCardProps,
});
