import PropTypes from 'prop-types';

import { radioCardGroupProps } from 'humanity/components/radioCardGroup/radioCardGroup.types';

export const formikRadioCardGroupProps = {
  ...radioCardGroupProps,
};

export const formikRadioCardGroupType = PropTypes.shape({
  ...formikRadioCardGroupProps,
});
