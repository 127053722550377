import { track } from 'utils/analytics';
import { logger } from 'utils/logger';

const EMAIL_COLLECTED = 'email_collected';
export function isEmailCollected() {
  return !!localStorage.getItem(EMAIL_COLLECTED);
}

export function trackEmailCollected(value, vwoClient, hinAnonymousId) {
  if (isEmailCollected()) {
    return;
  }
  track('email_collected', value);

  if (vwoClient) {
    try {
      const vwoSuccess = vwoClient.track(null, hinAnonymousId, 'email_collected');
      logger.info(`VWO track email_collected`, vwoSuccess, value);
    } catch (err) {
      logger.error(`Error tracking email_collected event in VWO`, err);
    }
  }
  localStorage.setItem(EMAIL_COLLECTED, 'true');
}
