import PropTypes from 'prop-types';

export const tooltipProps = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-end',
    'auto-start',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start',
  ]),
  name: PropTypes.string.isRequired,
  portal: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export const tooltipDefaultProps = {
  placement: 'auto',
  portal: false,
  onShow: () => {},
  onHide: () => {},
};

export const tooltipType = PropTypes.shape({});
