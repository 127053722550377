import { produce } from 'immer';

import { InputFragment } from '../../formikInput/input.fragment';
import { inputWithConditionalInputsStandard } from '../../inputWithConditionalInputs';
import { InputWithConditionalInputsFragment } from '../../inputWithConditionalInputs/inputWithConditionalInputs.fragment';

export const signupStepWithSingleRadio = {
  sys: {
    id: '5CSYyp80gVryAzC2WFrkSR',
  },
  title: 'What is your payroll provider?',
  subtitle: null,
  bottomText: 'I go on the bottom',
  inputsCollection: {
    items: [
      {
        sys: {
          id: '6hA6sClIckTqjIeZIAfJZR',
        },
        type: 'radio',
        label: 'Payroll provider',
        required: true,
        fieldName: 'payrollProvider',
        placeholder: null,
        width: 'full',
        autocompleteValue: null,
        optionsCollection: {
          items: [
            {
              sys: {
                id: '21mCyxomfFv4ogr7gKuEy',
              },
              label: 'Gusto',
              value: 'Gusto',
            },
            {
              sys: {
                id: '46I2V3Y1o9P37TazaarFha',
              },
              label: 'Rippling',
              value: 'Rippling',
            },
            {
              sys: {
                id: '7h096yCZdF7t2l9L6Pq9Mc',
              },
              label: 'Other',
              value: 'Other',
            },
          ],
        },
      },
    ] as InputFragment[],
  },
};

export const signupStepWithMultipleInputs = {
  sys: {
    id: 'LmRnyJe4aJwLlX3HqMVuw',
  },
  title: 'Tell us more about you',
  subtitle: null,
  bottomText: 'I go on the bottom',
  inputsCollection: {
    items: [
      {
        sys: {
          id: '6hWMcoyJxhcLHm9Y4HCuK5',
        },
        type: 'email',
        hidden: false,
        label: 'Work email',
        required: true,
        fieldName: 'email',
        placeholder: null,
        width: 'full',
        autocompleteValue: 'email',
        optionsCollection: {
          items: [],
        },
      },
      {
        sys: {
          id: '6SlZTlEjGzJtE8e5Ieonfe',
        },
        type: 'select',
        hidden: false,
        label: 'Your role',
        required: true,
        fieldName: 'role',
        placeholder: null,
        width: 'full',
        autocompleteValue: 'organization-title',
        optionsCollection: {
          items: [
            {
              sys: {
                id: '7hIDupuo2PjynuJuwqeInY',
              },
              label: 'CEO/Owner',
              value: 'CEO/Owner',
            },
            {
              sys: {
                id: '7LWxkcnelQzVNinzRpkXQ0',
              },
              label: 'Office Manager',
              value: 'Office Manager',
            },
            {
              sys: {
                id: '32huYkzZmBvO21dJQaPmuI',
              },
              label: 'Operations',
              value: 'Operations',
            },
            {
              sys: {
                id: '3ArYIwV26FCfa5F4i1jEZq',
              },
              label: 'Finance',
              value: 'Finance',
            },
            {
              sys: {
                id: 'BDwYT6pSD3WKL9wbcGANj',
              },
              label: 'Human Resources/Recruiting',
              value: 'Human Resource/Recruiting',
            },
            {
              sys: {
                id: '7mmTCLOfINuhUryT2DpFwE',
              },
              label: 'Other',
              value: 'Other',
            },
            {
              sys: {
                id: '6KZgp4QgdecbXwoQMfbFBp',
              },
              label: 'Employee / Trying to Login',
              value: 'Employee / Trying to Login',
            },
          ],
        },
      },
    ] as InputFragment[],
  },
};

export const signupStepWithHiddenInputs = produce(signupStepWithMultipleInputs, (draft) => {
  draft.inputsCollection.items[1].hidden = true;
})

export const signupStepWithConditionalInputs = {
  ...signupStepWithSingleRadio,
  inputsCollection: {
    items: [inputWithConditionalInputsStandard] as Array<
      InputFragment | InputWithConditionalInputsFragment
    >,
  },
};
