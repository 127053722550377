'use client';

import { Children, useState, useEffect } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Form, Formik } from 'formik';
import { ErrorBoundary } from 'react-error-boundary';

import {
  signupStepsContainerProps,
  signupStepsFormProps,
} from 'humanity/sections/signupStepsContainer/signupStepsContainer.types.js';
import { buildDefaultValidationSchema } from 'utils/buildDefaultValidationSchema';
import Section from 'humanity/primitives/section';
import Container from 'humanity/primitives/container';
import Grid from 'humanity/primitives/grid';
import Flex from 'humanity/primitives/flex';
import TextButton from 'humanity/primitives/textButton';
import Button from 'humanity/primitives/button';
import Card from 'humanity/components/card';
import { SignupStep } from 'humanity/components/signupStep';
import { useFastTrack } from 'hooks/useFastTrack';
import SignupModal from 'features/signup/components/signupModal';
import { track } from 'utils/analytics';
import { logger } from 'utils/logger';
import { SignupError } from 'humanity/components/signupError';

const StepCard = styled(Card)(
  css({
    gridColumnStart: ['1', null, null, '2'],
    gridColumnEnd: ['13', null, null, '12'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    borderRadius: [0, null, null, 'sm'],
    boxShadow: ['none', null, null, 'card'],
    px: [0, null, null, 5],
    py: 6,
    textAlign: 'center',
  })
);

export const SignupStepsForm = ({ children, initialValues, onSubmit }) => {
  const steps = Children.toArray(children);
  const [stepNumber, setStepNumber] = useState(
    initialValues.stepNumber > steps.length - 1 ? 0 : initialValues.stepNumber
  );
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  useEffect(() => {
    track('step_started', { ...snapshot, stepNumber: stepNumber + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNumber]);

  const handleNext = (values) => {
    const nextStepNumber = Math.min(stepNumber + 1, totalSteps - 1);
    setSnapshot({ ...values, stepNumber: nextStepNumber });
    setStepNumber(nextStepNumber);
  };

  const handlePrevious = (values) => {
    setSnapshot({ ...values, stepNumber: stepNumber - 1 });
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (values, bag) => {
    let showNextStep = false;
    track('step_completed', { ...values, stepNumber: stepNumber + 1 });

    if (step.props.onSubmit) {
      showNextStep = await step.props.onSubmit(
        { ...values, stepNumber: Math.min(stepNumber + 1, totalSteps - 1) },
        bag
      );
    }

    if (isLastStep) {
      return onSubmit({ ...values }, bag);
    }

    if (showNextStep) {
      bag.setTouched({});
      return handleNext({ ...values });
    }
  };

  const logBoundaryError = (error, info) => {
    // Do something with the error, e.g. log to an external API
    logger.critical('Error in signup steps container', error, info);
  };

  return (
    <Section bg={['white', null, null, 'transparent']}>
      <Container>
        <Grid>
          <StepCard>
            <ErrorBoundary fallback={SignupError} onError={logBoundaryError}>
              <Formik
                initialValues={snapshot}
                onSubmit={handleSubmit}
                validationSchema={buildDefaultValidationSchema(step.props.inputs)}
              >
                {(formik) => (
                  <Form data-testid="FastTrackForm">
                    {step}
                    <Flex gap={5} justifyContent="center" mt={5}>
                      {stepNumber !== 0 && (
                        <TextButton
                          onClick={() => handlePrevious(formik.values)}
                          color="tealDark"
                          fontWeight={600}
                          px="12px"
                          py="8px"
                        >
                          Back
                        </TextButton>
                      )}
                      <Button
                        type="submit"
                        variant="primary"
                        btnSize="md"
                        disabled={formik.isSubmitting}
                      >
                        {isLastStep ? 'Schedule a call' : 'Next'}
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </ErrorBoundary>
          </StepCard>
        </Grid>
      </Container>
    </Section>
  );
};

SignupStepsForm.propTypes = {
  ...signupStepsFormProps,
};

// eslint-disable-next-line no-unused-vars
export const SignupStepsContainer = ({
  employeeMaximum = null,
  steps,
  hubspotFormId,
  hubspotSandboxFormId,
  salesforceCampaignId,
  fastTrackPayrolls,
}) => {
  const {
    initialValues,
    handleNextStep,
    handleSubmit,
    bookingUrl,
    showModal,
    onModalClose,
  } = useFastTrack({
    fastTrackPayrolls,
    employeeMaximum,
    steps,
    formMetadata: {
      formType: 'Signup',
      hubspotFormId,
      hubspotSandboxFormId,
      salesforceCampaignId,
    },
  });

  return (
    <>
      <SignupModal bookingUrl={bookingUrl} isOpen={showModal} onClose={onModalClose} />
      {initialValues && (
        <SignupStepsForm initialValues={initialValues} onSubmit={handleSubmit}>
          {steps.map((step) => (
            <SignupStep
              key={step.sys.id}
              title={step.title}
              subtitle={step.subtitle}
              bottomText={step.bottomText}
              inputs={step.inputsCollection.items}
              onSubmit={handleNextStep}
            />
          ))}
        </SignupStepsForm>
      )}
    </>
  );
};

SignupStepsContainer.propTypes = {
  ...signupStepsContainerProps,
};
