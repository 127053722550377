import PropTypes from 'prop-types';

import { signupStepType } from 'humanity/components/signupStep';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const signupStepsFormProps = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const signupStepsContainerProps = {
  fastTrackPayrolls: PropTypes.arrayOf(PropTypes.string),
  employeeMaximum: PropTypes.number || null,
  steps: PropTypes.arrayOf(signupStepType).isRequired,
  hubspotFormId: PropTypes.string.isRequired,
  hubspotSandboxFormId: PropTypes.string.isRequired,
  salesforceCampaignId: PropTypes.string.isRequired,
};

export const signupStepsContainerType = PropTypes.shape({
  ...signupStepsContainerProps,
  sys: contentfulSysType.isRequired,
});
