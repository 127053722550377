import Flex from 'humanity/primitives/flex';
import Heading from 'humanity/primitives/heading';
import Link from 'humanity/primitives/link';
import Text from 'humanity/primitives/text';

const SignupError = () => (
  <Flex flexDirection="column" gap={4} textAlign="center">
    <Heading as="h1" variant="h3">
      Oops, something went wrong!
    </Heading>
    <Text variant="body">
      <Link href="/signup/" color="teal">
        Please try again
      </Link>{' '}
      or call us at{' '}
      <Link href="tel:877-475-0179" color="teal">
        877 475 0179
      </Link>{' '}
      to talk to a<br /> retirement specialist.
    </Text>
  </Flex>
);

export default SignupError;
