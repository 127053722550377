import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useRouter } from 'next/router';

import Modal from 'legacy/components/modal';

const Iframe = styled.iframe(
  css({
    width: '100%',
    height: '100%',
    border: 'none',
  })
);

const SignupModal = ({ bookingUrl, isOpen, onClose }) => {
  const router = useRouter();

  const BUSINESS_DOT_COM_PATH = '/lp/best-401k-for-affordability/';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel="Schedule a call"
      bg="offwhite"
      width="100%"
      height="100%"
      data-testid="SignupModal"
      css={{ overflow: 'hidden' }}
    >
      <Iframe title="Schedule a call" src={bookingUrl} />
      {isOpen && router.asPath === BUSINESS_DOT_COM_PATH && (
        <iframe
          title="Business.com affiliate"
          src="https://businesscom.go2cloud.org/aff_goal?a=l&goal_name=lead-form-submission&adv_id=614&offer_id=412"
          scrolling="no"
          frameBorder="0"
          width="1"
          height="1"
        />
      )}
    </Modal>
  );
};

SignupModal.propTypes = {
  bookingUrl: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SignupModal.defaultProps = {
  bookingUrl: null,
};

export default SignupModal;
