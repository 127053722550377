/**
 * Parses an employeesRange string and returns the min value as a number
 * @param {string} rangeStr An employees range string in the format "<min>-<max>", i.e. "100-999"
 * @returns {number}
 */
const parseEmployeesRange = (rangeStr) => rangeStr.split('-')[0];

/**
 * Calculates the estimated conversion value for a lead based on either the
 * `employees` property or an `employeesRange` property. Returns null if neither
 * are present
 * @param {{ employees?: string, employeesRange?: string }} lead
 * @returns {number | null}
 */
export const calculateConversionValue = (lead) => {
  let employees = null;

  if (lead.employees) {
    employees = parseInt(lead.employees, 10);
  } else if (lead.employeesRange) {
    employees = parseEmployeesRange(lead.employeesRange);
  }

  return employees ? (employees * 8 + 180) * 12 : null;
};
