import { inputFragment, InputFragment } from '../formikInput/input.fragment';
import { ContentfulSysInterface } from '../../../types/common/contentfulSys.types';

export const inputWithConditionalInputsFragment = ({
  conditionallyShownInputsCollectionLimit = 5,
  optionsLimit = 10,
  withImage = false,
}) => `
  ... on InputWithConditionalInputs {
    sys {
      id
    }
    input {
      ${inputFragment({ withImage })}
    }
    conditionallyShownInputsCollection(limit: ${conditionallyShownInputsCollectionLimit}) {
      items {
        ${inputFragment({ optionsLimit, withImage })}
      }
    }
  }
`;

export type InputWithConditionalInputsFragment = ContentfulSysInterface & {
  conditionallyShownInputsCollection: {
    items: InputFragment[];
  };
  input: InputFragment;
};
