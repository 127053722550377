import { inputWithConditionalInputsFragment } from '../inputWithConditionalInputs';
import { ContentfulSysInterface } from '../../../types/common/contentfulSys.types';
import { InputWithConditionalInputsFragment } from '../inputWithConditionalInputs/inputWithConditionalInputs.fragment';

import {
  InputFragment,
  inputFragment,
} from 'humanity/components/formikInput/input.fragment';

export const signupStepFragment = `
  ... on SignupStep {
    sys {
      id
    }
    title
    subtitle
    bottomText {
      json
    }
    redirectOnSubmit
    inputsCollection(limit: 10) {
      items {
        ${inputFragment({ withImage: false })}
        ${inputWithConditionalInputsFragment({ conditionallyShownInputsCollectionLimit: 5, optionsLimit: 2, withImage: false })}
      }
    }
  }
`;

export type SignupStepFragment = ContentfulSysInterface & {
  title: string;
  subtitle?: string | null;
  bottomText?: {
    json: string;
  };
  redirectOnSubmit: boolean;
  inputsCollection: {
    items: Array<InputFragment | InputWithConditionalInputsFragment>;
  };
};

export const signupStepSimplifiedFragment = `
  ... on SignupStep {
    sys {
      id
    }
    title
    subtitle
    bottomText {
      json
    }
    redirectOnSubmit
    inputsCollection(limit: 4) {
      items {
        ${inputFragment()}
      }
    }
  }
`;
