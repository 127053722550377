/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

import { compactBannerType, contentfulFormType, contentfulSysType } from 'types';
import { testimonialCardType } from 'legacy/components/humanity-v1/testimonialCard/testimonialCard.types';
import { statisticCardType } from 'legacy/components/humanity-v1/statisticsCard/statisticsCard.types';

export const signupPageType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  form: contentfulFormType.isRequired,
  statistics: statisticCardType.isRequired,
  testimonialsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(testimonialCardType),
  }).isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.oneOfType([compactBannerType])),
  }),
});
