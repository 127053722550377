export const inputWithConditionalInputsStandard = {
  sys: {
    id: '4STY2b7GMpSQnLtWScJWjM',
  },
  input: {
    sys: {
      id: '4DJUNNYTEN9ldqoErM7zZ',
    },
    tooltip: null,
    type: 'radio',
    label: 'Are you a nonprofit?',
    required: true,
    fieldName: 'non_profit',
    placeholder: null,
    width: 'full',
    autocompleteValue: null,
    defaultValue: {
      sys: {
        id: 'OMDnSUp2oO8T4PSldhwJA',
      },
      label: 'No',
      value: 'no',
    },
    optionsCollection: {
      items: [
        {
          sys: {
            id: '4l1mezDZ23Ue4Dz06JKABb',
          },
          label: 'Yes',
          value: 'yes',
        },
        {
          sys: {
            id: 'OMDnSUp2oO8T4PSldhwJA',
          },
          label: 'No',
          value: 'no',
        },
      ],
    },
  },
  conditionallyShownInputsCollection: {
    items: [
      {
        sys: {
          id: '2eTIgJ9LjfXy0Lo73s1Aph',
        },
        tooltip: null,
        type: 'radio',
        label: 'Is your business operating as a 501(c)(3)?',
        required: true,
        fieldName: 'is501c3',
        placeholder: null,
        width: 'full',
        autocompleteValue: null,
        defaultValue: {
          sys: {
            id: 'OMDnSUp2oO8T4PSldhwJA',
          },
          label: 'No',
          value: 'no',
        },
        optionsCollection: {
          items: [
            {
              sys: {
                id: '4l1mezDZ23Ue4Dz06JKABb',
              },
              label: 'Yes',
              value: 'yes',
            },
            {
              sys: {
                id: 'OMDnSUp2oO8T4PSldhwJA',
              },
              label: 'No',
              value: 'no',
            },
          ],
        },
      },
      {
        sys: {
          id: '0IB10EcO8bwonFpk1smOQ',
        },
        tooltip: null,
        type: 'radio',
        label: 'Is your business operating as a church?',
        required: true,
        fieldName: 'isChurch',
        placeholder: null,
        width: 'full',
        autocompleteValue: null,
        defaultValue: {
          sys: {
            id: 'OMDnSUp2oO8T4PSldhwJA',
          },
          label: 'No',
          value: 'no',
        },
        optionsCollection: {
          items: [
            {
              sys: {
                id: '4l1mezDZ23Ue4Dz06JKABb',
              },
              label: 'Yes',
              value: 'yes',
            },
            {
              sys: {
                id: 'OMDnSUp2oO8T4PSldhwJA',
              },
              label: 'No',
              value: 'no',
            },
          ],
        },
      },
    ],
  },
};
