// eslint-disable-next-line import/no-extraneous-dependencies
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import RecaptchaClient from 'apiClients/recaptcha';
import { track } from 'utils/analytics';
import { logger } from 'utils/logger';

const SCORE_THRESHOLD = 0.2;

export const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyRecaptcha = async (vals, action = 'form_submit') => {
    try {
      if (typeof executeRecaptcha !== 'function') {
        // have seen sporadic errors for this while testing. log for now.
        logger.error('executeRecaptcha is not defined', { action, ...vals });

        return true;
      }

      const token = await executeRecaptcha(action);

      const { score } = await RecaptchaClient.create({
        token,
        action,
        values: { ...vals },
      });

      if (score >= SCORE_THRESHOLD) {
        return true;
      }

      track('recaptcha_failed', { score, action, ...vals });
      logger.info('Low recaptcha score', { score, action, ...vals });
      return false;
    } catch (err) {
      logger.error('Error verifying recaptcha token - client', err, err.response?.data);
      return false;
    }
  };

  /**
   *
   * @param {function} onSubmit Submit handler for the form, called if the user
   *                            passes verification
   * @param {object}   vals     Form values
   * @param {string}   action   Action name corresponding to the token
   */
  const handleSubmitWithRecaptcha = async (
    onSubmit,
    vals = {},
    action = 'form_submit'
    // eslint-disable-next-line consistent-return
  ) => {
    const verified = await verifyRecaptcha(vals, action);

    if (verified && typeof onSubmit === 'function') {
      return onSubmit(vals);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleEventWithRecaptcha = async (event, onSuccess, action) => {
    const verified = await verifyRecaptcha({}, action);

    if (verified && typeof onSuccess === 'function') {
      onSuccess(event);
    }
  };

  return { handleSubmitWithRecaptcha, handleEventWithRecaptcha };
};
