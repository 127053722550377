export const radioCardGroupStandard = {
  name: 'foo',
  label: 'Is this the one?',
  defaultOption: {
    value: 'two',
    label: 'Two',
  },
  options: [
    {
      value: 'one',
      children: 'One',
      label: 'One',
    },
    {
      value: 'two',
      children: 'Two',
      label: 'Two',
    },
    {
      value: 'three',
      children: 'Three',
      label: 'Three',
    },
  ],
};
