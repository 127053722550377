import { type ContentfulSysInterface } from '../../../../types/common/contentfulSys.types';

export const imageFragment = `
  ... on Image {
    sys {
      id
    }
    altText
    width
    height
    image {
      title
      url
      description
    }
  }
`;

export type ImageFragment = ContentfulSysInterface & {
  altText?: string;
  width?: number;
  height?: number;
  image: {
    title: string;
    url: string;
    description: string;
  };
};
