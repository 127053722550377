import { useId } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as RadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';
import css from '@styled-system/css';
import { compose, flexbox, grid, layout, space } from 'styled-system';

import Box from 'humanity/primitives/box';
import {
  radioCardGroupProps,
  radioCardProps,
} from 'humanity/components/radioCardGroup/radioCardGroup.types';
import { theme } from 'utils/theme.v2';
import Tooltip from 'humanity/components/tooltip';
import ContentfulRichText from 'legacy/components/contentfulRichText';
import Information from 'humanity/icons/information';
import Flex from 'humanity/primitives/flex';

const StyledRadioCardGroup = styled(RadioGroup.Root)(
  css({
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    gap: 4,
  }),
  compose(space, layout, flexbox, grid)
);

const Label = styled(Box).attrs({ as: 'label' })(
  css({
    flexBasis: ['100%', null, '70%', null, 'auto'],
    color: 'blue100',
    fontWeight: 600,
  })
);

const StyledRadioCard = styled(RadioGroup.Item)(
  css({
    flexShrink: 0,
    width: '100%',
    height: 'auto',
    p: 4,
    bg: 'white',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'blue20',
    borderRadius: 'sm',
    fontFamily: 'body',
    fontSize: 'body',
    lineHeight: 'body',
    fontWeight: 700,
    color: 'blue100',
    appearance: 'none',
    cursor: 'pointer',
    '&:hover': {
      bg: 'blue10',
    },
    '&:focus': {
      boxShadow: 'buttonFocus',
    },
    '&[data-state=checked]': {
      borderColor: 'tealDark',
      bg: 'tealDark',
      color: 'white',
    },
  }),
  compose(space, layout, flexbox, grid)
);

const IconWrapper = styled(Box)(
  css({
    display: 'inline-block',
    ml: 2,
    verticalAlign: 'middle',
  })
);

export const RadioCard = ({
  id = null,
  value,
  label,
  small,
  buttonProps = {},
  children,
  ...rest
}) => {
  const uuid = `radiocard-${useId()}`;
  const inputId = id ?? uuid;

  return (
    <Box
      as="label"
      sx={{
        flexShrink: 0,
        width: small ? 'auto' : ['100%', null, 'auto'],
      }}
      htmlFor={inputId}
      {...rest}
    >
      <StyledRadioCard
        data-testid="RadioCard"
        id={inputId}
        value={value}
        aria-label={label}
        {...buttonProps}
      >
        {children}
      </StyledRadioCard>
    </Box>
  );
};

RadioCard.propTypes = {
  ...radioCardProps,
};

const RadioCardGroup = ({
  name,
  required = false,
  label,
  showLabel,
  tooltip,
  onChange,
  children,
  error,
  'aria-labelledby': ariaLabelledBy,
  optionGroupProps = {},
  ...rest
}) => {
  const labelId = `radio-card-group-label-${useId()}`;
  return (
    <StyledRadioCardGroup
      data-testid="RadioCardGroup"
      name={name}
      showLabel={showLabel}
      onValueChange={onChange}
      required={required}
      tooltip={tooltip}
      aria-labelledby={showLabel ? labelId : ariaLabelledBy}
      {...rest}
    >
      {showLabel ? (
        <>
          <Label id={labelId}>
            {label}{' '}
            {tooltip && (
              <Tooltip
                name={label}
                title={
                  <ContentfulRichText
                    content={tooltip}
                    maxWidth="300px"
                    p={1}
                    m={0}
                    fontWeight="normal"
                  />
                }
              >
                <IconWrapper>
                  <Information size={18} color={theme.colors.blue100} />
                </IconWrapper>
              </Tooltip>
            )}{' '}
            {error && <Box fontWeight="normal">{error}</Box>}
          </Label>
          <Flex gap={3} mx={['auto', null, 0]} {...optionGroupProps}>
            {children}
          </Flex>
        </>
      ) : (
        children
      )}
    </StyledRadioCardGroup>
  );
};

RadioCardGroup.propTypes = {
  ...radioCardGroupProps,
};

export default RadioCardGroup;
