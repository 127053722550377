import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

import FormGroup from 'humanity/components/formGroup';
import { formikRadioCardGroupProps } from 'humanity/components/formikRadioCardGroup/formikRadioCardGroup.types.js';
import InputError from 'humanity/components/inputError';
import { RadioCardGroup } from 'humanity/components/radioCardGroup';

const FormikRadioCardGroup = ({ name, required, defaultValue, children, ...rest }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (defaultValue && field.value === '') {
      setFieldValue(name, defaultValue.value);
    }
  }, [defaultValue, setFieldValue, field.value, name]);
  const handleChange = (val) => {
    setFieldValue(name, val);
  };

  return (
    <FormGroup span="full">
      <RadioCardGroup
        name={name}
        value={field.value}
        required={required}
        onChange={handleChange}
        error={meta.touched && meta.error && <InputError mt={2}>{meta.error}</InputError>}
        {...rest}
      >
        {children}
      </RadioCardGroup>
    </FormGroup>
  );
};

FormikRadioCardGroup.propTypes = {
  ...formikRadioCardGroupProps,
};

export default FormikRadioCardGroup;
