import { SignupStepFragment } from '../../../components/signupStep/signupStep.fragment';

export const signupStepsContainerStandard = {
  sys: {
    id: '3DQeqm5SphXBci6YG1nvdA',
  },
  hubspotFormId: 'af3554ff-20ee-4ca3-92ab-58bb6df7cd6e',
  hubspotSandboxFormId: '63bb8787-62c0-4acd-b82c-32d780bb8ecc',
  salesforceCampaignId: '7018X000001mnHFQAY',
  employeeMinimum: 100,
  fastTrackPayrolls: ['Gusto'],
  stepsCollection: {
    items: [
      {
        sys: {
          id: 'LmRnyJe4aJwLlX3HqMVuw',
        },
        title: 'Tell us more about you',
        subtitle: null,
        redirectOnSubmit: true,
        inputsCollection: {
          items: [
            {
              sys: {
                id: '6hWMcoyJxhcLHm9Y4HCuK5',
              },
              type: 'email',
              label: 'Work email',
              required: true,
              fieldName: 'email',
              placeholder: null,
              width: 'full',
              autocompleteValue: 'email',
              optionsCollection: {
                items: [],
              },
            },
            {
              sys: {
                id: '6SlZTlEjGzJtE8e5Ieonfe',
              },
              type: 'select',
              label: 'Your role',
              required: true,
              fieldName: 'role',
              placeholder: null,
              width: 'full',
              autocompleteValue: 'organization-title',
              optionsCollection: {
                items: [
                  {
                    sys: {
                      id: '7hIDupuo2PjynuJuwqeInY',
                    },
                    label: 'CEO/Owner',
                    value: 'CEO/Owner',
                    image: null,
                  },
                  {
                    sys: {
                      id: '7LWxkcnelQzVNinzRpkXQ0',
                    },
                    label: 'Office Manager',
                    value: 'Office Manager',
                    image: null,
                  },
                  {
                    sys: {
                      id: '32huYkzZmBvO21dJQaPmuI',
                    },
                    label: 'Operations',
                    value: 'Operations',
                    image: null,
                  },
                  {
                    sys: {
                      id: '3ArYIwV26FCfa5F4i1jEZq',
                    },
                    label: 'Finance',
                    value: 'Finance',
                    image: null,
                  },
                  {
                    sys: {
                      id: 'BDwYT6pSD3WKL9wbcGANj',
                    },
                    label: 'Human Resources/Recruiting',
                    value: 'Human Resource/Recruiting',
                    image: null,
                  },
                  {
                    sys: {
                      id: '7mmTCLOfINuhUryT2DpFwE',
                    },
                    label: 'Other',
                    value: 'Other',
                    image: null,
                  },
                  {
                    sys: {
                      id: '6KZgp4QgdecbXwoQMfbFBp',
                    },
                    label: 'Employee / Trying to Login',
                    value: 'Employee / Trying to Login',
                    image: null,
                  },
                ],
              },
            },
          ],
        },
      },
      {
        sys: {
          id: '1KVr8PMUlnbcwaPrD3AgSa',
        },
        title: 'Do you have an existing 401(k) plan?',
        subtitle: null,
        redirectOnSubmit: true,
        inputsCollection: {
          items: [
            {
              sys: {
                id: 'eFLimBjksrbBC8dDtQl7t',
              },
              type: 'radio',
              label: 'Do you have an existing 401(k) plan?',
              required: true,
              fieldName: 'currentPlan',
              placeholder: null,
              width: 'full',
              autocompleteValue: null,
              optionsCollection: {
                items: [
                  {
                    sys: {
                      id: '50qJhMHFVnvoa8QFAA2Eip',
                    },
                    label: 'Yes',
                    value: 'true',
                    image: null,
                  },
                  {
                    sys: {
                      id: '5qw1gkv5QTM1LvpUE93nkv',
                    },
                    label: 'No',
                    value: 'false',
                    image: null,
                  },
                ],
              },
            },
          ],
        },
      },
      {
        sys: {
          id: '5CSYyp80gVryAzC2WFrkSR',
        },
        title: 'What is your payroll provider?',
        subtitle: null,
        redirectOnSubmit: true,
        inputsCollection: {
          items: [
            {
              sys: {
                id: '6hA6sClIckTqjIeZIAfJZR',
              },
              type: 'radio',
              label: 'Payroll provider',
              required: true,
              fieldName: 'payrollProvider',
              placeholder: null,
              width: 'full',
              autocompleteValue: null,
              optionsCollection: {
                items: [
                  {
                    sys: {
                      id: '21mCyxomfFv4ogr7gKuEy',
                    },
                    label: 'Gusto',
                    value: 'Gusto',
                    image: {
                      sys: {
                        id: '3YRs5oC3h1sJM8eDzSVsk6',
                      },
                      altText: 'Gusto',
                      width: 83,
                      height: 32,
                      image: {
                        title: 'Gusto logo',
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/7DdxeuP8UT38ezzfce8SJ2/01e882a25b6f3aa098bea09b076fb54c/Gusto_logo_f45d48.png',
                        description: '',
                      },
                    },
                  },
                  {
                    sys: {
                      id: '46I2V3Y1o9P37TazaarFha',
                    },
                    label: 'Rippling',
                    value: 'Rippling',
                    image: {
                      sys: {
                        id: '7uIwqHGKPtKj2XBut1z22X',
                      },
                      altText: 'Rippling',
                      width: 140,
                      height: 20,
                      image: {
                        title: 'Rippling Logo',
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/3j4L07jVrCHc58T4oSkVEF/a85f67ecdc5a484be8e506fedb4e25f6/Rippling.png',
                        description: null,
                      },
                    },
                  },
                  {
                    sys: {
                      id: '7h096yCZdF7t2l9L6Pq9Mc',
                    },
                    label: 'Other',
                    value: 'Other',
                    image: null,
                  },
                ],
              },
            },
          ],
        },
      },
      {
        sys: {
          id: '4g1ygKUuZJZPnX8N2zWQT6',
        },
        title: 'How many employees do you have?',
        subtitle: null,
        redirectOnSubmit: true,
        inputsCollection: {
          items: [
            {
              sys: {
                id: '6Mr6htQpFPusiAk64inlIY',
              },
              type: 'radio',
              label: 'Number of employees',
              required: true,
              fieldName: 'employeesRange',
              placeholder: null,
              width: 'full',
              autocompleteValue: null,
              optionsCollection: {
                items: [
                  {
                    sys: {
                      id: '6aGJngIBZNjM7IklbkditR',
                    },
                    label: '1',
                    value: '1',
                    image: null,
                  },
                  {
                    sys: {
                      id: '66TLEXkenVeyCRCSFBbbzq',
                    },
                    label: '2-4',
                    value: '2-4',
                    image: null,
                  },
                  {
                    sys: {
                      id: '48wQhr2fDbkjULgNniPHeD',
                    },
                    label: '5-24',
                    value: '5-24',
                    image: null,
                  },
                  {
                    sys: {
                      id: '18FElfyGZarjHF4iv54ry7',
                    },
                    label: '25-99',
                    value: '25-99',
                    image: null,
                  },
                  {
                    sys: {
                      id: '6EAASPO6qhHjzVq6lfHygs',
                    },
                    label: '100-999',
                    value: '100-999',
                    image: null,
                  },
                  {
                    sys: {
                      id: '320pHTIg6QHfThDpMN2Dvw',
                    },
                    label: '1000+',
                    value: '1000+',
                    image: null,
                  },
                ],
              },
            },
          ],
        },
      },
    ] as SignupStepFragment[],
  },
};
