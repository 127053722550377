import { FC, ReactNode } from 'react';
import { useFormikContext } from 'formik';

type Props = {
  conditionallyShownInputs: ReactNode;
  input: ReactNode;
  fieldName: string;
  // eslint-disable-next-line no-unused-vars
  shouldShowConditionalInputs: (value: unknown) => boolean;
};

const InputWithConditionalInputs: FC<Props> = ({
  conditionallyShownInputs,
  input,
  fieldName,
  shouldShowConditionalInputs,
}) => {
  const { getFieldMeta } = useFormikContext();
  return (
    <>
      {input}
      {shouldShowConditionalInputs(getFieldMeta(fieldName).value) &&
        conditionallyShownInputs}
    </>
  );
};

export default InputWithConditionalInputs;
