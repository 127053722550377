import PropTypes from 'prop-types';

import {
  ContentfulDisclosures,
  contentfulDisclosuresType,
  ContentfulLayout,
  contentfulLayoutType,
  ContentfulSeoMetadata,
  contentfulSeoMetadataType,
  defaultPageType,
  lcCollectionPageType,
  semLandingPageType,
} from 'types';
import {
  ContentfulSysInterface,
  contentfulSysType,
} from 'types/common/contentfulSys.types';
import { signupPageType } from 'types/pages/signupPage.types';

/**
 * @deprecated
 * Use pageSettingsType along with the specific page content type
 * in future pages to avoid importing unnecessary proptypes
 */
export const pageContainerType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  page: PropTypes.oneOfType([
    lcCollectionPageType,
    semLandingPageType,
    defaultPageType,
    signupPageType,
  ]).isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});

/**
 * @deprecated Use {@link PageSettings} instead.
 */
export const pageSettingsType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});

export type PageSettings = ContentfulSysInterface & {
  internalTitle: string;
  slug: string;
  seoMetadata: ContentfulSeoMetadata;
  layout: ContentfulLayout;
  notes?: ContentfulDisclosures;
  disclosures?: ContentfulDisclosures;
};
