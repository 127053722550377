import PropTypes from 'prop-types';

import { contentfulStatisticType, contentfulSysType } from 'types';

export const statisticsCardProps = {
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  statisticsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulStatisticType),
  }).isRequired,
};

export const statisticCardType = PropTypes.shape({
  ...statisticsCardProps,
});
