import { Children, cloneElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { css } from '@styled-system/css';

import Card from 'humanity/components/card';
import {
  tooltipDefaultProps,
  tooltipProps,
} from 'humanity/components/tooltip/tooltip.types';

const Trigger = styled.span({
  position: 'relative',
  display: 'inline-block',
});

const StyledCard = styled(Card)(({ show }) =>
  css({
    display: show ? 'block' : 'none',
    boxShadow: 'tooltip',
    zIndex: 50,
    cursor: 'default',
  })
);

const Tooltip = ({
  title,
  placement,
  name,
  portal,
  onShow,
  onHide,
  cardProps,
  children,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'eventListeners',
        enabled: showTooltip,
      },
    ],
  });

  const handleShow = () => {
    setShowTooltip(true);
    if (typeof update === 'function') update();
    if (typeof onShow === 'function') onShow();
  };
  const handleHide = () => {
    setShowTooltip(false);
    if (typeof onHide === 'function') onHide();
  };

  const triggerProps = {
    ref: setReferenceElement,
    'aria-describedby': name,
    onFocus: handleShow,
    onBlur: handleHide,
    onMouseEnter: handleShow,
    onMouseLeave: handleHide,
    'data-testid': 'TooltipTrigger',
    ...props,
  };

  const shouldWrap = typeof children === 'string';

  let trigger;

  if (shouldWrap) {
    trigger = (
      <Trigger tabIndex={0} {...triggerProps}>
        {children}
      </Trigger>
    );
  } else {
    // Ensure tooltip only has one child node
    const child = Children.only(children);
    trigger = cloneElement(child, triggerProps);
  }

  const actualCardProps = {
    show: showTooltip,
    ref: setPopperElement,
    style: styles.popper,
    id: name,
    role: 'tooltip',
    bg: 'blue20',
    p: 3,
    'data-testid': 'TooltipCard',
    ...cardProps,
  };

  // If no tooltip content is passed in, just return the children
  if (!title) return children;

  return (
    <>
      {trigger}
      {!portal && (
        <StyledCard {...actualCardProps} {...attributes.popper}>
          {title}
        </StyledCard>
      )}
      {!!portal &&
        createPortal(
          <StyledCard {...actualCardProps} {...attributes.popper}>
            {title}
          </StyledCard>,
          document.body
        )}
    </>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  ...tooltipProps,
};

Tooltip.defaultProps = {
  ...tooltipDefaultProps,
};

export default Tooltip;
