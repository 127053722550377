import PropTypes from 'prop-types';

import { formikInputType } from 'humanity/components/formikInput/formikInput.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulRichTextType } from 'types';

const commonProps = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  redirectOnSubmit: PropTypes.bool.isRequired,
  bottomText: contentfulRichTextType,
};

export const signupStepType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  inputsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(formikInputType),
  }).isRequired,
});
