import { signupStepFragment } from 'humanity/components/signupStep';

/**
 * @type {String}
 */
export const signupStepsContainerFragment = `
  ... on SignupStepsContainer {
    sys {
      id
    }
    hubspotFormId
    hubspotSandboxFormId
    salesforceCampaignId
    employeeMaximum
    fastTrackPayrolls
    stepsCollection(limit: 10) {
      items {
        ${signupStepFragment}
      }
    }
  }
`;
