import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { logger } from 'utils/logger';
import { identify, track } from 'utils/analytics';
import convertLeadToSfdc from 'utils/convertLeadToSfdc';
import getOrSetTrackingParams from 'utils/getOrSetTrackingParams';
import ChiliPiperClient from 'apiClients/chiliPiper';
import HubspotClient from 'apiClients/hubspot';
import useAnalytics from 'hooks/useAnalytics';
import useVwo from 'hooks/useVwo';
import { generateOrderId } from 'utils/generateOrderId';
import { getSessionData } from 'utils/getSessionData';
import {
  CURRENT_EMPLOYEE_ROLES,
  CURRENT_EMPLOYEE_REDIRECT_URL,
  NEW_USER_REDIRECT_PATH,
} from 'utils/signupConstants';
import { calculateConversionValue } from 'utils/calculateConversionValue';
import { trackEmailCollected } from 'utils/emailCollected';

const submitLeadToSegment = (lead, analyticsData, isCurrEmployee) => {
  if (!isCurrEmployee) {
    track('schedule_call', {
      ...analyticsData,
    });
  }

  if (lead.email?.length) {
    const orderId = generateOrderId();
    // identify the lead in Segment
    identify({ ...analyticsData, ...lead });

    if (isCurrEmployee) {
      track('conversion_participant', {
        ...analyticsData,
        ...lead,
        order_id: orderId,
      });
    } else {
      const value = calculateConversionValue(lead);

      track('conversion_success', {
        ...analyticsData,
        ...lead,
        order_id: orderId,
        conversionSuccess: 'true',
        value,
      });
    }
  }
};

const submitLeadToVwo = (vwoClient, lead, hinAnonymousId, isCurrEmployee) => {
  // Don't trigger a conversion in VWO for self-identified current employees
  if (isCurrEmployee) {
    try {
      const vwoSuccess = vwoClient.track(null, hinAnonymousId, 'conversion_participant');
      logger.info(`VWO track participant`, vwoSuccess, lead);
      if (!vwoSuccess) {
        logger.error(`Error tracking conversion_participant event in VWO`, lead);
      }
    } catch (err) {
      logger.error(`Error tracking conversion_participant event in VWO`, err, lead);
    }
  } else {
    trackEmailCollected(lead, vwoClient, hinAnonymousId);
    try {
      const vwoSuccess = vwoClient.track(null, hinAnonymousId, 'conversion_success');
      logger.info(`VWO track conversion`, vwoSuccess, lead);
      if (!vwoSuccess) {
        logger.error(`Error tracking conversion_success event in VWO`, lead);
      }
    } catch (err) {
      logger.error(`Error tracking conversion_success event in VWO`, err, lead);
    }
  }
};

const submitLeadToHubspot = async (
  form,
  segmentAnonymousId,
  isCurrEmployee,
  hubspotParams
) => {
  // eslint-disable-next-line camelcase
  const { referrer, landing_page } = getSessionData();

  try {
    await HubspotClient.submitForm({
      form: {
        ...form,
        // this field both tells Hubspot to sync the lead to SFDC and tells SFDC
        // not to use its internal router
        Assigned_via_CP__c: !isCurrEmployee,
        referrer,
        // eslint-disable-next-line camelcase
        landing_page,
        form_type: hubspotParams?.formType?.toLowerCase() || '',
      },
      formId:
        process.env.NEXT_PUBLIC_HI_ENV === 'production'
          ? hubspotParams?.hubspotFormId
          : hubspotParams?.hubspotSandboxFormId,
      salesforceCampaignId: hubspotParams?.salesforceCampaignId,
    });
  } catch (err) {
    logger.error(`Error pushing lead Hubspot: ${form.email}`, err);
  }
};

const useSignupModal = (routerId = process.env.NEXT_PUBLIC_CP_DEFAULT_ROUTER_ID) => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [abandonTime, setAbandonTime] = useState(null);
  const [bookingUrl, setBookingUrl] = useState(null);
  const vwoClient = useVwo();
  const {
    analytics: { segmentAnonymousId, hinAnonymousId },
  } = useAnalytics();
  const trackingParams = getOrSetTrackingParams(router.query);
  const sessionData = getSessionData();

  const analyticsData = useMemo(
    () => ({
      ...trackingParams,
      ...sessionData,
    }),
    [trackingParams, sessionData]
  );

  useEffect(() => {
    // Runs when Chili Piper sends an event confirming the booking
    const receiveMessage = (event) => {
      const eventName = 'schedule_call_confirmed';
      if (event?.data?.action === 'booked') {
        // Track the event in Segment
        track(eventName, {
          ...analyticsData,
        });

        // Attempt to track the event in VWO
        try {
          const vwoSuccess = vwoClient.track(null, hinAnonymousId, eventName);
          logger.info(`VWO ${eventName} track result`, vwoSuccess, { hinAnonymousId });
          if (!vwoSuccess) {
            logger.error(`Error tracking ${eventName} event in VWO`, { hinAnonymousId });
          }
        } catch (err) {
          logger.error(`Error tracking ${eventName} event in VWO`, err, {
            hinAnonymousId,
          });
        }

        // Redirect the user to the thank you page
        setTimeout(() => {
          router.push(NEW_USER_REDIRECT_PATH);
        }, 2000);
      }
    };

    window.addEventListener('message', receiveMessage);

    return () => window.removeEventListener('message', receiveMessage);
  }, [analyticsData, hinAnonymousId, router, vwoClient]);

  const onModalClose = () => {
    track('schedule_call_abandon', {
      ...analyticsData,
    });
    setShowModal(false);

    if (abandonTime) {
      clearTimeout(abandonTime);
      setAbandonTime(null);
    }
  };

  useEffect(() => {
    const prepClose = () => {
      onModalClose();
    };

    if (showModal) {
      window.addEventListener('beforeunload', prepClose, { capture: true });

      if (abandonTime) {
        clearTimeout(abandonTime);
        setAbandonTime(null);
      }
      // "Abandon" the chili piper scheduling after 30 minutes.
      setAbandonTime(setTimeout(() => onModalClose(), 30 * 60 * 1000));
    } else {
      window.removeEventListener('beforeunload', prepClose, { capture: true });

      if (abandonTime) {
        clearTimeout(abandonTime);
        setAbandonTime(null);
      }
    }

    return () => window.removeEventListener('beforeunload', prepClose, { capture: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, setShowModal]);

  const submitLead = async (userData, contentfulForm) => {
    setIsLoading(true);

    try {
      const lead = {
        ...trackingParams,
        ...userData,
        segmentAnonymousId,
        hinAnonymousId,
        leadSource: 'Inbound',
      };

      const isCurrEmployee = CURRENT_EMPLOYEE_ROLES.includes(lead.role);

      // Payroll/Partner pages do some weird stuff, because our Salesforce
      // reporting is bad
      if (router.asPath.includes('payrolls/') || router.asPath.includes('partners/')) {
        if (router.query?.utm_source?.toLowerCase().includes('partner')) {
          lead.formFillPage = `${router.query.slug}_marketplace`;
        } else {
          lead.formFillPage = router.query.slug;
        }
      } else {
        lead.formFillPage = window.location.pathname;
      }

      submitLeadToSegment(lead, analyticsData, isCurrEmployee);
      submitLeadToVwo(vwoClient, lead, hinAnonymousId, isCurrEmployee);

      const form = convertLeadToSfdc(lead);

      if (
        (process.env.NEXT_PUBLIC_HI_ENV === 'production' &&
          contentfulForm?.hubspotFormId) ||
        (process.env.NEXT_PUBLIC_HI_ENV !== 'production' &&
          contentfulForm?.hubspotSandboxFormId)
      ) {
        await submitLeadToHubspot(
          form,
          segmentAnonymousId,
          isCurrEmployee,
          contentfulForm
        );
      }

      track('form_submitted', {
        ...userData,
        form_type: contentfulForm.formType?.toLowerCase() || '',
      });

      // If the lead has identified themselves as a current employee trying to
      // log in, redirect them to the appropriate page before showing the booking screen
      if (isCurrEmployee) {
        // Insert small timeout before redirect to ensure analytics events have
        // time to be sent
        await setTimeout(600);
        window.location.href = CURRENT_EMPLOYEE_REDIRECT_URL;
        return;
      }

      const options = {
        router: routerId,
        map: true,
      };

      const cpRes = await ChiliPiperClient.create({
        form,
        options,
      });

      setBookingUrl(`${cpRes.url}&hide_x=true`);
      setShowModal(true);
      setIsLoading(false);
    } catch (err) {
      logger.error(`Error creating booking URL for lead ${userData.email}`, err);
      router.push('/signup/error/');
    }
  };

  return {
    submitLead,
    bookingUrl,
    showModal,
    onModalClose,
    isLoading,
  };
};

export default useSignupModal;
