import PropTypes from 'prop-types';

export const radioCardProps = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const radioCardType = PropTypes.shape({
  ...radioCardProps,
});

export const radioCardGroupProps = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export const radioCardGroupType = PropTypes.shape({
  ...radioCardGroupProps,
});
